.App {
  text-align: center;
}

html,
body {
  width: 100%;
  height: 100%;
  background: #1b2127;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.example::-webkit-scrollbar {
  display: none;
}